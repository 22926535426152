import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="Stay Safe">
      <section className="text-larger stay-safe">
         <div className="row center-xs">
            <div className="col-xs-12 col-sm-9 col-lg-5">
               <h1 className="text-center margin-bottom-m">Stay Safe</h1>
               <p>Dear Language Instructor,</p>
               <p>With hundreds of schools and colleges around the country closing in response to the threat of
               COVID-19, the need for quality online teaching and learning resources for language instructors has
                    never been greater.</p>
               <p>
                  At Lingco, we are here to support you and your students during this difficult time:
                  <ul>
                     <li>Our powerful platform is a single place where you can easily create, assign, and assess a
                            variety of language activities.</li>
                     <li>Your students can move at their own pace with automatically graded assignments and
                            individualized vocabulary learning sessions.</li>
                     <li>Finally, Lingco's grading functionality tracks each student's progress and allows for speedy
                            grading and easy feedback.</li>
                  </ul>
               </p>
               <p>
                  We would like to offer you access to Lingco Classroom free of charge for the remainder of the school
                  year. Please fill out the form below we'll send you an instructor code.
               </p>
               <div className="text-center">
                  <OutboundLink
                     className="typeform-share btn"
                     href="https://sethkillian.typeform.com/to/nHVW5x"
                     data-mode="popup"
                     target="_blank" rel="noreferrer">Register</OutboundLink>
                  <script dangerouslySetInnerHTML={{
                     __html: `
                     (function () {
                        var qs, js, q, s, d = document,
                           gi = d.getElementById,
                           ce = d.createElement,
                           gt = d.getElementsByTagName,
                           id = "typef_orm_share",
                           b = "https://embed.typeform.com/";
                        if (!gi.call(d, id)) {
                           js = ce.call(d, "script");
                           js.id = id;
                           js.src = b + "embed.js";
                           q = gt.call(d, "script")[0];
                           q.parentNode.insertBefore(js, q)
                        }
                  })()
                  `}}>
                  </script>
               </div>
            </div>
         </div>
      </section>
   </Layout>
);

export default About;
